<template>
  <!--    Inicio sección "banners"-->
  <section id="fondo-puntos-vertical" ref="fondo-puntos-vertical" class="bg-secondary" style="overflow: hidden">
    <div class="container">
      <div
        id="linea-puntos-vertical"
        style="overflow: hidden;position: absolute !important;z-index: 0;height: 0px;width: auto;"
      >
        <img src="/img/caminos/camino-2.svg" alt="" />
      </div>
      <div class="row py-5 z-index-up">
        <div class="w-100 m-prefooter"></div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="mb-5 pb-5 text-white"><strong>Disfruta con un turismo responsable</strong></h2>
            <img
              style="max-width: 200px"
              src="/img/turismo-responsable/sierra-norte-turismo-responsable.svg"
              alt="Turismo Responsable en Sierra Norte de Madrid"
            />
          </div>
          <div class="col-12 mt-5" style="margin-bottom: 150px">
            <div class="row d-flex justify-content-center">
              <div class="col-6 col-sm-2 text-center">
                <img
                  class="w-75"
                  src="/img/turismo-responsable/turismo-responsable-distancia-seguridad.svg"
                  alt="Turismo Responsable en Sierra Norte de Madrid"
                />
              </div>
              <div class="col-6 col-sm-2 text-center">
                <img
                  class="w-75"
                  src="/img/turismo-responsable/turismo-responsable-usa-mascarilla.svg"
                  alt="Turismo Responsable en Sierra Norte de Madrid"
                />
              </div>
              <div class="col-6 col-sm-2 text-center">
                <img
                  class="w-75"
                  src="/img/turismo-responsable/turismo-responsable-manos-limpias.svg"
                  alt="Turismo Responsable en Sierra Norte de Madrid"
                />
              </div>
              <div class="col-6 col-sm-2 text-center">
                <img
                  class="w-75"
                  src="/img/turismo-responsable/turismo-responsable-respete-el-aforo.svg"
                  alt="Turismo Responsable en Sierra Norte de Madrid"
                />
              </div>
              <div class="col-6 col-sm-2 text-center">
                <img
                  class="w-75"
                  src="/img/turismo-responsable/turismo-responsable-cuida-del-entorno.svg"
                  alt="Turismo Responsable en Sierra Norte de Madrid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5 my-sm-5 z-index-up">
        <div class="col-sm-6 order-sm-2">
          <img src="/img/sierra-norte-es-historia.jpg" alt="" class="img-fluid mb-5 mb-sm-0" />
        </div>
        <div class="col-sm-5 offset-sm-1 text-white mb-0 text-sm-right order-sm-1">
          <h2 class="mb-4">Conoce todos los planes para este mes</h2>
          <a href="https://www.sierranortemadrid.org/agenda/" target="_blank" class="btn btn-primary btn-large">
            Descúbrelos
          </a>
        </div>
      </div>
      <div class="w-100 m-prefooter"></div>
    </div>
  </section>
  <!--    Fin sección banners-->
</template>
<script>
export default {
  mounted() {
    if (window.innerWidth > 1000) {
      const scene3 = this.$scrollmagic
        .scene({
          triggerElement: '#fondo-puntos-vertical',
          duration: this.$refs['fondo-puntos-vertical'].clientHeight
        })
        .setTween('#linea-puntos-vertical', {
          height: this.$refs['fondo-puntos-vertical'].clientHeight // Calculo el height porque puede cambiar
        })
      // .addIndicators() // add indicators (requires plugin)

      this.$scrollmagic.addScene(scene3)
    }
  }
}
</script>
